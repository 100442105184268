import { ComponentType, useState } from "react"
import { Component, useEffect } from "react"
import { AnimatePresence, motion, stagger } from "framer-motion"
import { addPropertyControls, ControlType } from "framer"

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight fixed
 */

export default function Grid(props) {
    return (
        <motion.div
            animate={{ scaleY: [0, 1] }}
            transition={{
                duration: 2,
                ease: [0.075, 0.82, 0.165, 1],
            }}
            style={{
                ...props.style,
                transformOrigin: "bottom left",
                backgroundImage: `linear-gradient(90deg, ${props.background} 1px, transparent 1px)`,
                backgroundSize: `${Math.round(100 / props.columns)}.01%`,
                marginLeft: "-1px",
                opacity: props.opacity,
            }}
        />
    )
}

addPropertyControls(Grid, {
    columns: {
        type: ControlType.Number,
        defaultValue: 4,
        min: 0,
        max: 8,
        step: 1,
        displayStepper: true,
    },
    opacity: {
        type: ControlType.Number,
        defaultValue: 0.15,
        min: 0,
        max: 1,
    },
    background: {
        type: ControlType.Color,
        defaultValue: "rgba(255, 255, 255, 1)",
    },
})
